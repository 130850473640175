<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" :maxWidth="id && getAccess(`${typeObject}.site`) ? '100hv' : '800px'">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.city + " " + data.address : `Новый '${config.title}'` }}
      </template>
      <v-row v-if="id && getAccess(`${typeObject}.site`)">
        <v-col cols="12" sm="6">
          <v-card-title class="pt-0"> Информация об объекте </v-card-title>
          <v-row class="mt-1" :style="{ height: $root.windowSize.innerHeight - 150 - 50 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
            <v-col cols="12" class="py-0 pt-1">
              <a-form-model
                :ref="'field_status_site'"
                v-model="data"
                :model="getFormModel(['status_site'], model)"
                :errors="errors"
                :config="{ dense: true, readonly: !getAccess('site.status') }"
                @validate="onChangeStatus($event)"
              />
            </v-col>
            <a-form-modelCols
              v-model="data"
              class="pb-3 mx-1 pt-2"
              :model="
                getFormModel(
                  'source,name,link_ref,city#6,address#6,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro,photos,photos_design'.split(
                    ','
                  )
                )
              "
              :values="data"
              :errors="errors"
              :config="{ dense: true, readonly, hideDetails: 'auto' }"
              @validate="validate($event)"
            />
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" :style="{ height: $root.windowSize.innerHeight - 150 + 'px' }" style="overflow-y: auto; overflow-x: hidden">
          <s-props-form
            v-if="value"
            :model="{ title: 'Дополнительные данные для сайта', id, type: typeObject }"
            :id="id"
            :type="typeObject"
            :config="propsConfig"
            :objectData="data"
          />
        </v-col>
      </v-row>
      <v-layout v-else px-2 py-2 mt-1 row wrap>
        <a-form-modelCols
          v-model="data"
          class="pb-3 mx-1 pt-2"
          :model="
            getFormModel(
              'source,name,link_ref,city#6,address#6,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law'.split(
                ','
              )
            )
          "
          :values="data"
          :errors="errors"
          :config="{ dense: true, readonly, hideDetails: 'auto' }"
          @validate="validate($event)"
        />
      </v-layout>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="canSave" @click="submit()" class="pr-2"></a-btn-save>
        <a-btn-delete v-if="id && getAccess(`${typeObject}.delete`)" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";

export default {
  mixins: [getAccess, getForm, submitForm, removeEl, genModel],
  props: {
    value: Boolean,
    typeObject: { type: String, default: "lampObjects" },
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects,
      removeDialogShow: false,
      suEditShow: false,
      panelPlan: null,
    };
  },
  computed: { 
    config() {
      console.log("config", this.m?.config);
      const res = this.m?.config?.[this.typeObject] || {};
      console.log("config2", this.typeObject, res);
      return res;
    },
    model() {
      //let config = JSON.parse(JSON.stringify(this.m?.config?.considers || {}));
      let model = this.calcModelConfig(this.config);
      return model;
    },
    propsConfig() {
      let res = {};
      res.readonly = !this.getAccess("site.edit");
      return res;
    },
    name() {
      if (!this.data) return "";
      return this.data.city + " " + this.data.address;
    },
    vendor: {
      get() {
        let res = this.data.data?.vendor || "";
        return res;
      },
      set(v) {
        if (!this.data.data?.vendor) this.data.data = Object.assign({}, this.data.data, { vendor: "" });

        this.data.data.vendor = v;
      },
    },
    readonly() {
      if (!this.id) return false;
      return !this.getAccess(this.typeObject + ".edit", {
        id: this.id,
        data: this.data,
      });
    },
    canSave() {
      return this.getAccess(`${this.typeObject}.edit`) || (this.getAccess(`${this.typeObject}.create`) && !this.id);
    },
  },
  watch: {
    "data.ap_price_buy"() {
      this.calcPrice_m2();
    },
    "data.ap_area"() {
      this.calcPrice_m2();
    },

    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },

  created() {
    this.makeObjectModel();
  },
  methods: {
    calcPrice_m2() {
      this.data.price_m2 = "";
      if (this.data.ap_area && this.data.ap_price_buy) {
        this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
      }
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: this.typeObject });
    },
    async afterSave(data, status) {
      if (status) this.$emit("input");
    },
  },
};
</script>
