var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit',{attrs:{"value":_vm.value,"maxWidth":_vm.id && _vm.getAccess(`${_vm.typeObject}.site`) ? '100hv' : '800px'},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.id)?_c('small',[_vm._v(" ("+_vm._s(_vm.id)+")")]):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? _vm.data.city + " " + _vm.data.address : `Новый '${_vm.config.title}'`)+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.canSave)?_c('a-btn-save',{staticClass:"pr-2",on:{"click":function($event){return _vm.submit()}}}):_vm._e(),(_vm.id && _vm.getAccess(`${_vm.typeObject}.delete`))?_c('a-btn-delete',{on:{"click":function($event){_vm.removeDialogShow = true}}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[(_vm.id && _vm.getAccess(`${_vm.typeObject}.site`))?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card-title',{staticClass:"pt-0"},[_vm._v(" Информация об объекте ")]),_c('v-row',{staticClass:"mt-1",staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},style:({ height: _vm.$root.windowSize.innerHeight - 150 - 50 + 'px' })},[_c('v-col',{staticClass:"py-0 pt-1",attrs:{"cols":"12"}},[_c('a-form-model',{ref:'field_status_site',attrs:{"model":_vm.getFormModel(['status_site'], _vm.model),"errors":_vm.errors,"config":{ dense: true, readonly: !_vm.getAccess('site.status') }},on:{"validate":function($event){return _vm.onChangeStatus($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1),_c('a-form-modelCols',{staticClass:"pb-3 mx-1 pt-2",attrs:{"model":_vm.getFormModel(
                'source,name,link_ref,city#6,address#6,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro,photos,photos_design'.split(
                  ','
                )
              ),"values":_vm.data,"errors":_vm.errors,"config":{ dense: true, readonly: _vm.readonly, hideDetails: 'auto' }},on:{"validate":function($event){return _vm.validate($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)],1),_c('v-col',{staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},style:({ height: _vm.$root.windowSize.innerHeight - 150 + 'px' }),attrs:{"cols":"12","sm":"6"}},[(_vm.value)?_c('s-props-form',{attrs:{"model":{ title: 'Дополнительные данные для сайта', id: _vm.id, type: _vm.typeObject },"id":_vm.id,"type":_vm.typeObject,"config":_vm.propsConfig,"objectData":_vm.data}}):_vm._e()],1)],1):_c('v-layout',{attrs:{"px-2":"","py-2":"","mt-1":"","row":"","wrap":""}},[_c('a-form-modelCols',{staticClass:"pb-3 mx-1 pt-2",attrs:{"model":_vm.getFormModel(
            'source,name,link_ref,city#6,address#6,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law'.split(
              ','
            )
          ),"values":_vm.data,"errors":_vm.errors,"config":{ dense: true, readonly: _vm.readonly, hideDetails: 'auto' }},on:{"validate":function($event){return _vm.validate($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)],1),_c('remove-dialog',{on:{"remove":function($event){return _vm.remove(_vm.api, _vm.id)}},model:{value:(_vm.removeDialogShow),callback:function ($$v) {_vm.removeDialogShow=$$v},expression:"removeDialogShow"}}),(_vm.getAccess('suData'))?_c('su-Data',{ref:"suEdit",attrs:{"api":_vm.api,"id":_vm.id,"activate":_vm.value,"dataEdit":_vm.data},on:{"show":function($event){_vm.suEditShow = true}},model:{value:(_vm.suEditShow),callback:function ($$v) {_vm.suEditShow=$$v},expression:"suEditShow"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }